/* eslint-disable @typescript-eslint/no-floating-promises */
import { Component, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { PushNotifications } from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { FeatureFlagService } from '@inyova/inyova-shared';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { register } from 'swiper/element/bundle';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as AccountActions from '@account/account.actions';
import * as RootActions from '@app/app.actions';
import * as GrowActions from '@grow/inyova-grow.actions';

import { IonicStorageKeys, LocalStorageKeys } from '@app/app.constants';
import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { State } from '@shared/models/State';
import { AuthService } from '@shared/services/auth.service';
import { LanguageService } from '@shared/services/language.service';
import { NotificationService } from '@shared/services/notification.service';
import { RemoteConfigService } from '@shared/services/remote-config.service';
import { TrackingService } from '@shared/services/tracking.service';
import { NotificationResources } from '@shared/yova-api/notification.resources';
import { environment } from 'src/environments/environment';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  isOffline = false;
  networkHandler: any;
  navigationSubscription: Subscription;
  pushNotificationRegistered = false;

  constructor(
    private authService: AuthService,
    private modalController: ModalController,
    private device: Device,
    private navCtrl: NavController,
    private faio: FingerprintAIO,
    private languageService: LanguageService,
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private store: Store<State>,
    private remoteConfigService: RemoteConfigService,
    private notificationService: NotificationService,
    private notificationResources: NotificationResources,
    private trackingService: TrackingService,
    private zone: NgZone,
    private statusBarStyling: StatusBarStylingService,
    private featureFlagService: FeatureFlagService
  ) {}

  initializeApp() {
    this.platform.ready().then(() => {
      // Set status bar default value
      this.statusBarStyling.setStyle('light');
      // Set background on Android
      this.statusBarStyling.setBackgroundColor('base');
      // Check if biometric login is available and save in store
      this.isBiometricAuthAvailable();
      // detect network status
      this.getNetworkStatus();
      // Set initial app language if there is user from before
      this.languageService.setInitialAppLanguage();
      // Add listener on network changes
      this.networkHandler = Network.addListener('networkStatusChange', (res) => {
        this.isOffline = !res.connected;
      });
      // Handle deep linking
      App.addListener('appUrlOpen', (data) => {
        this.zone.run(() => {
          const slug = data.url.split('/product').pop();
          if (slug) {
            // update redirect url with deeplink url
            localStorage.setItem(LocalStorageKeys.REDIRECT_AFTER_LOGOUT, `/tabs${slug}`);
            if (this.authService.isAuthenticated()) {
              void (slug.includes('?account=') ? this.router.navigateByUrl(`/login`) : this.router.navigateByUrl(`/tabs${slug}`));
            }
          }
        });
      });
    });

    this.authService.authenticationState.subscribe((state) => {
      if (Object.keys(state).length) {
        if ((!state.step_status || state.step_status < 5) && !state.isCrowdinvestor) {
          this.navCtrl.navigateForward('/public/onboarding', { animated: false });
        } else if (window.location.pathname === '/public/login' || window.location.pathname === '/public/login/2fa') {
          // Request permission to use push notifications
          // iOS will prompt user and return if they granted permission or not
          // Android will just grant without prompting
          this.storage.get(IonicStorageKeys.PUSH_NOTIFICATIONS_PREF).then((value) => {
            if (!(value === true || value === false)) {
              this.registerPushNotifications();
            }
          });

          // Redirect after customer click on system push notification
          const redirectUrl = localStorage.getItem(LocalStorageKeys.REDIRECT_AFTER_LOGOUT);
          const pushNotification = localStorage.getItem(LocalStorageKeys.TRACK_PUSH_NOTIFICATION);

          // Crowdinvestor without any account or previously selected CI account
          const isCrowdinvestorPriority =
            state.isCrowdinvestor && (state?.step_status !== 5 || localStorage.getItem(LocalStorageKeys.CURRENT_ACCOUNT_ID) === '');
          if (isCrowdinvestorPriority) {
            this.store.dispatch(AccountActions.setCrowdInvestorAccountStatus({ active: true }));
          }

          // Push notifications redirection
          if (redirectUrl) {
            // TODO: Check it with Lola & Lenka
            if (pushNotification) {
              this.trackingService.trackActivity(pushNotification.split('/')[0]);
              if (!redirectUrl.includes('/engagement') || !redirectUrl.includes('startSurvey')) {
                this.notificationResources.markAsSeen(Number(pushNotification.split('/')[1])).subscribe();
              }
              this.router.navigateByUrl(redirectUrl);
              localStorage.removeItem(LocalStorageKeys.REDIRECT_AFTER_LOGOUT);
            } else {
              this.router.navigate(['']);
              localStorage.removeItem(LocalStorageKeys.TRACK_PUSH_NOTIFICATION);
            }
            this.router.navigateByUrl(redirectUrl);
            localStorage.removeItem(LocalStorageKeys.REDIRECT_AFTER_LOGOUT);
          } else {
            // if there is no account delete current account ID
            if (isCrowdinvestorPriority) {
              localStorage.setItem(LocalStorageKeys.CURRENT_ACCOUNT_ID, '');
            }
            this.router.navigateByUrl('');
          }
        }
        this.featureFlagService.initialize(environment);
        // Track page visit on every page
        this.navigationSubscription = this.router.events.subscribe((val) => {
          if (val instanceof NavigationEnd) {
            if (this.platform.is('ios')) {
              if (val.url.includes('/home/campaign')) {
                this.statusBarStyling.setStyle('dark');
              } else {
                this.statusBarStyling.setStyle('light');
              }
            }
            this.trackingService.trackView(this.router.url);
          }
        });
      } else {
        // If there is a modal opened, close it
        this.modalController.getTop().then((val) => {
          if (val) {
            this.modalController.dismiss();
          }
        });

        // Navigate to login page after logout
        if (window.location.pathname !== '/public/login') {
          this.navCtrl.navigateRoot('/public/login', { animated: false });
        }
        // Unsubscribe tracking on logout
        if (this.navigationSubscription) {
          this.navigationSubscription.unsubscribe();
        }
      }
    });
    // Subscribe to pause event (user pause the app)
    this.platformPaused();
    // Subscribe to resume event (user resume the app)
    this.platformResumed();
  }

  async ngOnInit() {
    await this.storage.create();

    this.initializeApp();

    SplashScreen.show({
      showDuration: 0,
      fadeInDuration: 0,
      autoHide: false
    });
    if (Capacitor.isNativePlatform()) {
      // We need to act only when a user clicks on a notification outside of an app
      // When an app is active, we do not care if notification arrives, since we pull this
      // directly from backend
      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        const redirectLink: string = this.notificationService.getRedirectPage(notification.notification.data.page);
        if (!environment.production) {
          console.log('notification', notification.notification);
        }
        const appPaused = localStorage.getItem(LocalStorageKeys.APP_PAUSED);
        // Save given page, since user is not loged in
        if (!this.authService.isAuthenticated() || (appPaused && new Date(Number(appPaused)) <= new Date())) {
          if (notification?.notification?.data?.account_id) {
            localStorage.setItem(LocalStorageKeys.CURRENT_ACCOUNT_ID, notification.notification.data.account_id);
          }
          localStorage.setItem(LocalStorageKeys.REDIRECT_AFTER_LOGOUT, redirectLink);
          localStorage.setItem(
            LocalStorageKeys.TRACK_PUSH_NOTIFICATION,
            `${this.getPushNotificationTitle(notification)}/${notification.notification.data.customer_notification_id}`
          );
        } else if (!redirectLink.includes('/engagement') || !redirectLink.includes('startSurvey')) {
          this.notificationResources.markAsSeen(Number(notification.notification.data.customer_notification_id)).subscribe();
          this.redirectWhileLoggedIn(notification, redirectLink);
        }
      });

      // Listener to PushNotifications.register event, receive the token and save it in database
      PushNotifications.addListener('registration', (token) => {
        if (!this.pushNotificationRegistered) {
          this.pushNotificationRegistered = true;
          this.store.dispatch(AccountActions.addNewDevice({ deviceUuid: this.device.uuid, token: token.value }));
        }
      });
    }
  }

  private async getNetworkStatus() {
    await Network.getStatus().then((res) => (this.isOffline = !res.connected));
  }

  private isBiometricAuthAvailable() {
    const biometricKeys = {
      Android: {
        finger: 'biometric'
      },
      iOS: {
        face: 'FaceID',
        finger: 'TouchID'
      }
    };
    this.faio
      .isAvailable()
      .then((res) => {
        const biometricName = biometricKeys[this.device.platform] ? biometricKeys[this.device.platform][res] : 'biometric';
        return this.store.dispatch(RootActions.setBiometricLogin({ payload: { available: true, type: res, name: biometricName } }));
      })
      .catch(() => this.store.dispatch(RootActions.setBiometricLogin({ payload: { available: false, type: null, name: null } })));
  }

  private platformPaused() {
    this.platform.pause.subscribe(() => {
      this.storage.get(IonicStorageKeys.BIOMETRIC_LOGIN_PREF).then((value) => {
        const datePaused = new Date();
        const delayedValue = value ? 1 : 5;
        const datePausedPlus = datePaused.setMinutes(datePaused.getMinutes() + delayedValue);
        localStorage.setItem(LocalStorageKeys.APP_PAUSED, `${datePausedPlus}`);
      });
    });
  }

  private platformResumed() {
    this.platform.resume.subscribe(() => {
      const appPaused = localStorage.getItem(LocalStorageKeys.APP_PAUSED);
      // If customer paused the app for more than a minute (or 5), check for authentication
      if (appPaused && new Date(Number(appPaused)) <= new Date()) {
        this.navCtrl.navigateRoot('/public/login', { animated: false });
        this.store.dispatch(AccountActions.customerLogout());
      }
      // Clean localstorage variable
      localStorage.removeItem(LocalStorageKeys.APP_PAUSED);
      // Check token on Inyova for kids page, after customer returns from the mobile browser
      if (this.router.url.includes('/account/yova-for-kids') || this.router.url.includes('/account/3a')) {
        this.authService.checkToken(true);
      } else if (this.router.url.includes('public/login')) {
        this.remoteConfigService.fetchData();
      }
    });
  }

  private redirectWhileLoggedIn(notification, redirectLink: string) {
    this.trackingService.trackActivity(this.getPushNotificationTitle(notification));

    if (notification?.notification?.data?.account_id) {
      this.store.dispatch(AccountActions.getCurrentAccount({ id: notification.notification.data.account_id, redirectURL: redirectLink }));
      return;
    }

    // if grow related notification dispatch grow account
    if (notification?.notification?.data?.page.includes('grow')) {
      this.store.dispatch(RootActions.toggleGrowAccount({ active: true }));
      this.store.dispatch(GrowActions.getInyovaGrowAccount());
    }

    void this.router.navigateByUrl(redirectLink);
  }

  private registerPushNotifications() {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === 'granted') {
          this.pushNotificationRegistered = false;
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          // Save preference of the customer
          this.storage.set(IonicStorageKeys.PUSH_NOTIFICATIONS_PREF, true);
        } else {
          // Save preference of the customer
          this.storage.set(IonicStorageKeys.PUSH_NOTIFICATIONS_PREF, false);
        }
      });
    }
  }

  private getPushNotificationTitle(notification) {
    if (!notification && !notification.notification) {
      return '';
    }

    const title = notification.notification.title || notification.notification.data.page.split('home/')[1];
    let notificationTitle = `[Push notification] ${title}`;
    if (notification.notification.data && notification.notification.data.type === 'impact_story') {
      if (notification.notification.data.page.includes('/engagement')) {
        notificationTitle = `[Push notification] Impact news - ${notification.notification.data.page.split('/engagement/')[1]}`;
      } else {
        notificationTitle = `[Push notification] Impact news - ${notification.notification.data.page.split('/impact-news/')[1]}`;
      }
    }
    return notificationTitle;
  }
}
