import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureFlagService } from '../services/feature-flags.service';

@Directive({
  selector: '[appFeatureFlag]'
})
export class FeatureFlagDirective {
  @Input() set appFeatureFlag(flagName: string) {
    this.hasFeature = this.featureFlagService.featureEnabled(flagName);
    this.elseTemplateRef = null;
    this.updateView();
  }

  @Input() set appFeatureFlagElse(templateRef: TemplateRef<any>) {
    this.assertTemplate('ngIfElse', templateRef);
    this.elseTemplateRef = templateRef;
    this.updateView();
  }

  hasFeature: boolean;
  flagName: string;
  elseTemplateRef: TemplateRef<any>;

  constructor(
    private featureFlagService: FeatureFlagService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  private updateView() {
    if (this.hasFeature) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      if (this.elseTemplateRef) {
        this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      } else {
        this.viewContainer.clear();
      }
    }
  }

  private assertTemplate(property: string, templateRef: TemplateRef<any> | null) {
    const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
    if (!isTemplateRefOrNull) {
      throw new Error(`${property} must be a TemplateRef, but received '${templateRef}'.`);
    }
  }
}
