import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Jsona from 'jsona';
import { TJsonApiBody } from 'jsona/lib/JsonaTypes';

import { map, Observable } from 'rxjs';

import { FeatureFlag } from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private jsona = new Jsona();
  private _featureFlags: Map<string, boolean> = new Map();
  private _initialized = false;
  constructor(private http: HttpClient) {}

  initialize(environment) {
    return this.getFeatureFlags(environment).subscribe((featureFlags) => {
      this._featureFlags = new Map(featureFlags.map(({ feature_key, value }) => [feature_key, value]));
      this._initialized = true;
    });
  }

  getFeatureFlags(environment): Observable<FeatureFlag[]> {
    return this.http
      .get<TJsonApiBody>(`${environment.apiUrlV3}/flipper_gates`)
      .pipe(map((response) => this.jsona.deserialize({ ...response }) as FeatureFlag[]));
  }

  get initialized() {
    return this._initialized;
  }

  featureDisabled(featureName: string) {
    return !this.featureEnabled(featureName);
  }

  featureEnabled(featureName: string) {
    if (!featureName) {
      return true;
    }
    return this._featureFlags && !!this._featureFlags.get(featureName);
  }
}
